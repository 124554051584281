import React from "react"

import contentData from "../../../components/staticSections/CtaST/data.json"

const CtaST = ({ lang, ctaHref, ctaDataGoal, ctaButtonText }) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = contentData[language]
  return (
    <section data-theme="light" className="CtaST">
      <div className="container container--md margin-top--lg margin-bottom--lg">
        {content &&
          content.map(main => (
            <div
              key={main}
              className="text--component margin-bottom--md text--center"
            >
              <h2 className="margin-bottom--xxs">{main.title}</h2>
              <p>{main.text}</p>

              <a
                href={ctaHref}
                data-goal={ctaDataGoal}
                className="btn btn--primary btn--md ctaMargin"
              >
                {main.buttonText}
              </a>

              <p className="text--sm margin-top--sm text--center ">
                {main.subTextOne}{" "}
                <a data-goal="Email Clicked" href={`mailto:${main.email}`}>
                  {main.email}{" "}
                </a>
                {main.subTextTwo}{" "}
                <a data-goal="Phone Clicked" href={`tel:+${main.BackEndPhone}`}>
                  {main.FrontEndPhone}
                </a>
              </p>
            </div>
          ))}
      </div>
    </section>
  )
}

export default CtaST
