import React from 'react';
import contentData from './data.json';

const FaqST = ({ lang }) => {
  const language = typeof lang === 'undefined' ? 'se_sv' : lang;
  const content = contentData[language];

  return (
    <section data-theme="white" className="FaqST">
      {content
        && content.map((main) => (
          <div key={main} className="container container--md">
            <div className="container container--sm margin-top--lg margin-bottom--lg">
              <div className="text--center margin-bottom--xs">
                <h2>{main.title}</h2>
              </div>
              <ul
                data-animation="on"
                data-multi-items="off"
                className="accordion accordion"
              >
                {main.faq
                  && main.faq.map((faq) => (
                    <li
                      key={faq.question}
                      className="accordion__item accordion__item"
                    >
                      <button
                        data-goal="FAQ Clicked"
                        className="accordion__header"
                        type="button"
                      >
                        <span className="accordion__header-title">
                          {faq.question}
                        </span>
                        <em
                          aria-hidden="true"
                          className="accordion__header-icon"
                        >
                          <i />
                        </em>
                      </button>
                      <div className="accordion__panel accordion__panel">
                        <div className="accordion__panel-content">
                          <div className="text--component margin-bottom--md text--sm">
                            <p>{faq.answer}</p>
                            <p>{faq.subText}</p>
                            <div className="margin-top--xs FaqList">
                              {faq.list
                                && faq.list.map((list) => (
                                  <p key={list.dataList}>{list.dataList}</p>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
    </section>
  );
};

export default FaqST;
